.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: wrap;
    margin: 3rem;
}

.container > * {
    min-width: 25rem;
    max-width: 40rem;
}

.image {
    border-radius: 3rem;
    flex: 1 0;
    object-fit: contain;
    height: 35rem;
    width: 70%;
    margin: 1rem;
}

.details {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 0;
    border-radius: 3rem;
    padding: 2rem;
    color: black;
    margin-left: 1rem;

}

.header {
    display: flex;  
    justify-content: space-between;
}

.name {
    font-size: 2rem;
    font-weight: bold;
}

.code {
    margin-top: 5rem;
    font-size: 1.5rem;
}

.content {
    color: white;
    background-color: #e72929;
    border: none;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 10rem;
    outline: none;
    text-align: center;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}