.container {
    margin: auto;
    margin-top: 1rem;
    max-width: 70rem;
    width: 100%;
  }
  
  .menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .menu a {
    width: 22rem;
    height: 11rem;
    margin: 1.5rem 2rem;
    background-color: whitesmoke;
    box-shadow: 0 0 5px 1px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    color: #444444;
    justify-content: space-around;
  }
  
  .menu img {
    width: 8rem;
    height: 8rem;
    margin: 1rem;
  }
  
  .menu a:hover {
    box-shadow: 2px 2px 10px 1px grey;
  }
  
  .menu h2 {
    font-size: 2.5rem;
    margin-right: 2rem;
  }