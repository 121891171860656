.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 100;
    height: 15rem;
}

.container a {
    font-size: 1rem;
    background-color: #e72929;
    color: white;
    border-radius: 10rem;
    padding: 0.7rem 1rem;
    margin: 1rem;
    opacity: 0.8;
}

.container a:hover {
    opacity: 1;
    cursor: pointer;
}