.container {
    display: flex;
    justify-content: center;
    margin-bottom: 10rem;
  }
  
  .content,
  .form {
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    width: 95%;
  }
  
  .image_link {
    display: block;
    width: 100%;
    height: 20rem;
    border-radius: 1rem;
    border: 1px solid #e0e0e0;
    margin: 0.5rem 0 1rem 0;
  }
  
  .image_link img {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }