.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
  }
  
  .details {
    width: 28rem;
  }
  
  .details form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .register {
    display: flex;
    justify-content: center;
    align-items: center;
  }