.container {
    position: relative;
    margin-bottom: 0.5rem;
    border-radius: 0.7rem;
    padding-top: 0.3rem;
    border: 1px solid #e0e0e0;
  }
  
  .label {
    display: inline-block;
    margin-left: 0.5rem;
    color: #5f5f5f;
    font-size: 1rem;
  }
  
  .content {
    padding: 0 0.5rem;
    height: 2.7rem;
    display: flex;
    align-items: center;
  }