.list {
    background-color: rgb(255, 46, 46);
    display: flex;
    justify-content: center;  
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.list li a {
    background-color: rgb(242, 170, 170);
    height: 22.5rem;
    width: 20rem;
    border: 1px solid whitesmoke;
    border-radius: 1rem;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: black;
}

.image {
    object-fit: cover;
    height: 14.5rem;
}

.content {
    margin-top: 0.3rem;
    padding: 0.5rem 1rem;
    position: relative;
    height: 7rem;
}



.codeText {
    position: absolute;
    bottom: 1.4rem;
    font-size: 16px;
}

.code {
    position: absolute;
    bottom: 0;
}

.disable-link {
    pointer-events: none;
    opacity: 0.5;
}