.container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffffea;
    z-index: 10000;
    top: 0;
    left: 0;
  }
  
  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
  }
  

  
  .container h1 {
    color: rgb(201, 11, 11);
    text-transform: lowercase;
  }