.container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.container input {
    border-radius: 10rem 0 0 10rem;
    border: none;
    height: 3rem;
    width: 15rem;
    background-color: #e9e9e9;
    padding: 1.2rem;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
}

.container button {
    color: gray;
    height: 3rem;
    width: 5rem;
    font-size: 1rem;
    border-radius: 0 10rem 10rem 0;
    border: none;
    background-color: #e72929;
    color: white;
    opacity: 0.8;
}

.container button:hover {
    opacity: 1;
    cursor: pointer;

}